<template>
  <v-container>
    <div class="document">
      <div class="document-form">
        <v-form ref="form" @submit.prevent="ValidateForm">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-select :label="$t('vacation.category vacation')" outlined :items="listHolidayType" item-text="name"
                item-value="id" v-model="item.category_holiday_id" :hint="`${server_errors.category_holiday_id}`"
                persistent-hint @keydown.enter="focusNext" dense>
              </v-select>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field :label="$t('vacation.name')" outlined v-model="item.name" :hint="`${server_errors.name}`"
                persistent-hint @keydown.enter="focusNext" dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-checkbox v-model="amount_by_dates"
                :label="item.company_holiday_date.length > 0 ? $t('vacation.holiday_according_date') + `(${item.company_holiday_date.length})` : $t('vacation.holiday_according_date')"
                class="lfont"></v-checkbox>
              <div v-if="amount_by_dates" class="mb-5">
                <v-row>
                  <v-col cols="12" md="2" sm="2">
                    <v-btn e depressed color="primary" style="color: white;" @click="dialog_add_date = true">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="10" sm="10">
                    <v-row>
                      <v-col cols="12" md="4" sm="4" v-for="(item, index) in item.company_holiday_date">
                        <p> {{ index + 1 }}. {{ item }} <v-btn icon color="pink" @click="delete_date(item)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn></p>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>


              </div>

              <div v-else>
                <v-text-field :label="$t('vacation.amount')" outlined type="number" class="input-number"
                  v-model="item.amount" :hint="`${server_errors.amount}`" persistent-hint @keydown.enter="focusNext"
                  dense>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field :label="$t('vacation.month_can_use')" outlined type="number" class="input-number"
                v-model="item.month_can_use" :hint="`${server_errors.month_can_use}`" persistent-hint
                @keydown.enter="focusNext" dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
                {{ $t("vacation.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <v-dialog v-model="dialog_add_date" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"><span class="lfont">ເລືອກວັນທີ່ພັກ</span></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-card>
                  <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text fixed-tabs color="dark">
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-2" @click="chooseTab(2)">
                      <span class="lfont">ເລືອກວັນທີ່</span>
                      <v-icon>mdi-calendar-range</v-icon>
                    </v-tab>

                    <v-tab href="#tab-1" @click="chooseTab(1)">
                      <span class="lfont">ຈາກວັນພັກ</span>
                      <v-icon>mdi-calendar</v-icon>
                    </v-tab>


                  </v-tabs>

                  <v-tabs-items v-model="tab" class="pb-6">
                    <v-tab-item :value="'tab-1'">
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6" sm="6" v-for="item in listCompamyHoliday">
                              <v-checkbox :label="item.name" color="primary" v-model="companyHolidaySelected"
                                :value="item.id" hide-details class="lfont"></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item :value="'tab-2'">
                      <v-card flat>
                        <v-card-text>
                          <DatePicker class="date-time" style="width: 100%" type="date" v-model="date" valueType="format"
                            slot="activator" :append-to-body="true"></DatePicker>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>

                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>


          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="get_date_to_add">
            Save
          </v-btn>
          <v-btn color="red darken-1" text @click="dialog_add_date = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading v-if="isLoading" />
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";
export default {
  components: {
    DatePicker,
    Loading
  },
  data() {
    return {
      isLoading: false,
      tab: null,
      date: "",
      dialog_add_date: false,
      amount_by_dates: false,
      btnLoading: false,
      selected: [],
      listHolidayType: [],
      listCompamyHoliday: [],
      companyHolidaySelected: [],
      item: {
        category_holiday_id: "",
        name: "",
        amount: 0,
        month_can_use: "",
        company_holiday_date: [],
        status: "normal"
      },
      server_errors: {
        category_holiday_id: "",
        name: "",
        amount: "",
        month_can_use: "",
        company_holiday_date: "",
        status: ""
      },
    };
  },
  computed: {
    filterHolidaySelected() {
      return this.listCompamyHoliday.filter(item => {
        return this.companyHolidaySelected.includes(item.id)
      })
    }
  },
  methods: {
    chooseTab(tab) {
      if (tab == 2)
        this.companyHolidaySelected.splice(0, this.companyHolidaySelected.length);
    },
    delete_date(date) {
      const dates = [...this.item.company_holiday_date]

      const index = dates.indexOf(date)

      // Remove the second value from the array
      if (index >= 0) {
        dates.splice(index, 1)
      }

      this.item.company_holiday_date = dates.sort((a, b) => new Date(a) - new Date(b));
    },
    get_date_to_add() {
      this.isLoading = true;
      this.dialog_add_date = false;
      if (this.companyHolidaySelected.length > 0) {
        this.selected = this.filterHolidaySelected.map(item => item.value);
        this.$axios.get(`company/calculate/public/holiday`, {
          params: {
            "start_date": null,
            "type": this.selected
          }
        }).then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            const result = res.data.data;
            this.item.company_holiday_date = result.map(item => item.date);
            this.item.status = 'public';
          }
        });
      } else {
        this.isLoading = false;
        let check = this.item.company_holiday_date.filter(date => date == this.date);
        if (check.length == 0 && this.date != '') {
          let new_value = [...this.item.company_holiday_date, this.date];
          this.item.company_holiday_date = new_value.sort((a, b) => new Date(a) - new Date(b));
          this.item.status = 'dates';
        }

        this.date = '';
      }

    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    fetchHolidayType() {
      this.$axios.get(`company/list/category/holidays`).then((res) => {
        if (res.status === 200) {
          this.listHolidayType = res.data.data;
        }
      });
    },
    fetchCompanyHoliday() {
      this.$axios.get(`company/public/holiday/list`).then((res) => {
        if (res.status === 200) {
          this.listCompamyHoliday = res.data.data;
        }
      });
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    saveChange() {
      if (!this.amount_by_dates) this.item.status = 'normal';
      if (this.item.amount == '') this.item.amount = 0;
      this.$axios
        .post(`company/add/holiday`, this.item)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.$router.push({ name: "vacation.index" });
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  created() {
    this.fetchHolidayType();
    this.fetchCompanyHoliday();
  },
};
</script>

<style scoped lang="scss">
.document {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .document-form {
    width: 700px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
